import React from 'react';
import { ShoppingCartIcon } from '@heroicons/react/outline';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference, ReferenceLink } from 'helpers/reference';
import Image from 'frontastic/lib/image';

interface CartButtonProps {
  cartItemCount?: number;
  cartLink?: Reference;
}

const CartButton: React.FC<CartButtonProps> = ({ cartItemCount, cartLink }) => {
  //i18n messages
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

  return (
    <div className="flow-root">
      <ReferenceLink ariaLabel="my cart" target={cartLink} className="header-icon icon-cart group relative">
        {cartItemCount > 0 && (
          <>
            <span className="item-number">
              <span className="flex h-full w-full items-center justify-center text-xs text-white">
                {cartItemCount}
              </span>
            </span>
            <span className="sr-only">
              {formatCartMessage({
                id: 'cart.items.in.view',
                defaultMessage: 'items in cart, view cart',
              })}
            </span>
          </>
        )}
      </ReferenceLink>
    </div>
  );
};

export default CartButton;
